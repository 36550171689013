import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { DanhMucNgonNguComponent } from './danh-muc-ngon-ngu/danh-muc-ngon-ngu.component';
import { DanhMucQuocGiaComponent } from './danh-muc-quoc-gia/danh-muc-quoc-gia.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { DanhMucTinhThanhComponent } from './danh-muc-tinh-thanh/danh-muc-tinh-thanh.component';
import { DanhMucTinhTrangComponent } from './danh-muc-tinh-trang/danh-muc-tinh-trang.component';
import { DanhMucUngDungComponent } from './danh-muc-ung-dung/danh-muc-ung-dung.component';
import { DanhMucXaPhuongComponent } from './danh-muc-xa-phuong/danh-muc-xa-phuong.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienChucNangComponent } from './thanh-vien-chuc-nang/thanh-vien-chuc-nang.component';
import { ThanhVienKhuVucComponent } from './thanh-vien-khu-vuc/thanh-vien-khu-vuc.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienTokenComponent } from './thanh-vien-token/thanh-vien-token.component';
import { DanhMucQuanHuyenComponent } from './danh-muc-quan-huyen/danh-muc-quan-huyen.component';
import { DanhMucChauLucComponent } from './danh-muc-chau-luc/danh-muc-chau-luc.component';
import { DanhMucToChucComponent } from './danh-muc-to-chuc/danh-muc-to-chuc.component';
import { ToChucComponent } from './to-chuc/to-chuc.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { LoHangComponent } from './lo-hang/lo-hang.component';
import { DanhMucDonViTinhComponent } from './danh-muc-don-vi-tinh/danh-muc-don-vi-tinh.component';
import { DanhMucLoaiHinhComponent } from './danh-muc-loai-hinh/danh-muc-loai-hinh.component';
import { DanhMucNganhNgheComponent } from './danh-muc-nganh-nghe/danh-muc-nganh-nghe.component';
import { DanhMucNhomSanPhamComponent } from './danh-muc-nhom-san-pham/danh-muc-nhom-san-pham.component';
import { DanhMucPhuongThucSanXuatComponent } from './danh-muc-phuong-thuc-san-xuat/danh-muc-phuong-thuc-san-xuat.component';
import { DanhMucQuyTrinhComponent } from './danh-muc-quy-trinh/danh-muc-quy-trinh.component';
import { DanhMucSanPhamComponent } from './danh-muc-san-pham/danh-muc-san-pham.component';
import { DanhMucTapTinDinhKemPhanLoaiComponent } from './danh-muc-tap-tin-dinh-kem-phan-loai/danh-muc-tap-tin-dinh-kem-phan-loai.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { HuongDanComponent } from './huong-dan/huong-dan.component';
import { TemPhieuKhoiTaoComponent } from './tem-phieu-khoi-tao/tem-phieu-khoi-tao.component';
import { ToChucTemPhieuComponent } from './to-chuc-tem-phieu/to-chuc-tem-phieu.component';
import { DonViToChucKhoTemComponent } from './don-vi-to-chuc-kho-tem/don-vi-to-chuc-kho-tem.component';
import { DonViToChucDetailComponent } from './don-vi-to-chuc-detail/don-vi-to-chuc-detail.component';
import { DonViLoHangComponent } from './don-vi-lo-hang/don-vi-lo-hang.component';
import { DonViToChucTemPhieuKhoiTaoComponent } from './don-vi-to-chuc-tem-phieu-khoi-tao/don-vi-to-chuc-tem-phieu-khoi-tao.component';
import { DonViToChucTemPhieuKhoiTaoChiTietComponent } from './don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet/don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet.component';
import { DonViToChucTemPhieuKhoiTaoChiTietActiveComponent } from './don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet-active/don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet-active.component';
import { TemPhieuKhoiTao001Component } from './tem-phieu-khoi-tao001/tem-phieu-khoi-tao001.component';
import { ThanhVienThongBaoComponent } from './thanh-vien-thong-bao/thanh-vien-thong-bao.component';
import { DonViLoHangInfoComponent } from './don-vi-lo-hang-info/don-vi-lo-hang-info.component';
import { LoHangInfoComponent } from './lo-hang-info/lo-hang-info.component';
import { LoHangInfoByQRCodeComponent } from './lo-hang-info-by-qrcode/lo-hang-info-by-qrcode.component';


const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: 'Homepage', component: HomepageComponent,
  },
  {
    path: 'Login', component: LoginComponent,
  },  
  {
    path: 'GioiThieu', component: GioiThieuComponent,
  },  
  {
    path: 'HuongDan', component: HuongDanComponent,
  },  

  {
    path: 'DanhMucChucNang', component: DanhMucChucNangComponent,
  },  
  {
    path: 'DanhMucNgonNgu', component: DanhMucNgonNguComponent,
  },  
  {
    path: 'DanhMucQuanHuyen', component: DanhMucQuanHuyenComponent,
  }, 
  {
    path: 'DanhMucQuocGia', component: DanhMucQuocGiaComponent,
  },  
  {
    path: 'DanhMucChauLuc', component: DanhMucChauLucComponent,
  },  
  {
    path: 'DanhMucThanhVien', component: DanhMucThanhVienComponent,
  },  
  {
    path: 'DanhMucTinhThanh', component: DanhMucTinhThanhComponent,
  },  
  {
    path: 'DanhMucTinhTrang', component: DanhMucTinhTrangComponent,
  }, 
  {
    path: 'DanhMucUngDung', component: DanhMucUngDungComponent,
  }, 
  {
    path: 'DanhMucXaPhuong', component: DanhMucXaPhuongComponent,
  }, 
  {
    path: 'DanhMucToChuc', component: DanhMucToChucComponent,
  }, 
  {
    path: 'DanhMucDonViTinh', component: DanhMucDonViTinhComponent,
  }, 
  {
    path: 'DanhMucLoaiHinh', component: DanhMucLoaiHinhComponent,
  }, 
  {
    path: 'DanhMucNganhNghe', component: DanhMucNganhNgheComponent,
  }, 
  {
    path: 'DanhMucNhomSanPham', component: DanhMucNhomSanPhamComponent,
  }, 
  {
    path: 'DanhMucPhuongThucSanXuat', component: DanhMucPhuongThucSanXuatComponent,
  }, 
  {
    path: 'DanhMucQuyTrinh', component: DanhMucQuyTrinhComponent,
  }, 
  {
    path: 'DanhMucSanPham', component: DanhMucSanPhamComponent,
  }, 
  {
    path: 'DanhMucTapTinDinhKemPhanLoai', component: DanhMucTapTinDinhKemPhanLoaiComponent,
  }, 

 

  {
    path: 'ThanhVien', component: ThanhVienComponent,
  }, 
  {
    path: 'ThanhVienChucNang', component: ThanhVienChucNangComponent,
  }, 
  {
    path: 'ThanhVienKhuVuc', component: ThanhVienKhuVucComponent,
  }, 
  {
    path: 'ThanhVienLichSuTruyCap', component: ThanhVienLichSuTruyCapComponent,
  }, 
  {
    path: 'ThanhVienToken', component: ThanhVienTokenComponent,
  },  
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },  
  {
    path: 'ThanhVienThongBao', component: ThanhVienThongBaoComponent,
  },  

  {
    path: 'ToChuc', component: ToChucComponent,
  },  

  {
    path: 'LoHang', component: LoHangComponent,
  },  
  {
    path: 'TemPhieuKhoiTao', component: TemPhieuKhoiTaoComponent,
  }, 
  {
    path: 'TemPhieuKhoiTao001', component: TemPhieuKhoiTao001Component,
  }, 
  {
    path: 'ToChucTemPhieu', component: ToChucTemPhieuComponent,
  },  
  {
    path: 'LoHangInfo/:ID', component: LoHangInfoComponent,
  },
  {
    path: 'LoHangInfoByQRCode/:ID', component: LoHangInfoByQRCodeComponent,
  },

  {
    path: 'DonViLoHang', component: DonViLoHangComponent,
  },  
  {
    path: 'DonViToChucDetail', component: DonViToChucDetailComponent,
  },  
  {
    path: 'DonViToChucKhoTem', component: DonViToChucKhoTemComponent,
  },  
  {
    path: 'DonViToChucTemPhieuKhoiTao', component: DonViToChucTemPhieuKhoiTaoComponent,
  },  
  {
    path: 'DonViToChucTemPhieuKhoiTaoChiTiet', component: DonViToChucTemPhieuKhoiTaoChiTietComponent,
  },  
  {
    path: 'DonViToChucTemPhieuKhoiTaoChiTietActive', component: DonViToChucTemPhieuKhoiTaoChiTietActiveComponent,
  },  
  {
    path: 'DonViLoHangInfo/:ID', component: DonViLoHangInfoComponent,
  },  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































