<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-TemPhieuKhoiTaoDetail" data-bs-toggle="tab" href="#TemPhieuKhoiTaoDetail"
                role="tab" aria-controls="TemPhieuKhoiTaoDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Lô Tem (Mã số truy vết sản phẩm) do Cơ quan quản lý
                    cấp [{{TemPhieuKhoiTaoService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="TemPhieuKhoiTaoDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <button style="width: 100%;" title="Lưu thay đổi" (click)="TemPhieuKhoiTaoSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</button>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="TemPhieuKhoiTaoService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsThuHoi"
                            [(ngModel)]="TemPhieuKhoiTaoService.FormData.IsThuHoi" />
                        <label class="form-check-label" for="IsThuHoi">Thu hồi</label>
                    </div>
                </div> -->
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị sử dụng
                            [{{TemPhieuKhoiTaoService.FormData.ToChucID}}]</label>
                        <input *ngIf="TemPhieuKhoiTaoService.FormData.ID>0" name="ToChucName"
                            [(ngModel)]="TemPhieuKhoiTaoService.FormData.ToChucName" placeholder="Đơn vị sử dụng"
                            type="text" disabled class="form-control">
                        <mat-select *ngIf="TemPhieuKhoiTaoService.FormData.ID==0" class="form-control"
                            name="TemPhieuKhoiTaoService.FormData.ToChucID"
                            [(ngModel)]="TemPhieuKhoiTaoService.FormData.ToChucID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="ToChucFilter($event.target.value)">                           
                            <mat-option *ngFor="let item of ToChucService.ListFilter" [value]="item.ID">
                                {{item.DanhMucUngDungName}} - <b>{{item.Name}}</b> - {{item.DienThoai}} -
                                {{item.MaSoThue}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã lô</label>
                        <input name="Code" [(ngModel)]="TemPhieuKhoiTaoService.FormData.Code" placeholder="Mã lô"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận</label>
                        <input [ngModel]="TemPhieuKhoiTaoService.FormData.GhiNhan | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="TemPhieuKhoiTaoService.FormData.Note" placeholder="Ghi chú"
                            type="text" class="form-control">
                    </div>

                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng</label>
                        <input name="SoLuong" [(ngModel)]="TemPhieuKhoiTaoService.FormData.SoLuong" placeholder="0"
                            [disabled]="TemPhieuKhoiTaoService.FormData.ID>0" type="number" style="text-align: right;"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sử dụng</label>
                        <input name="SuDung" [(ngModel)]="TemPhieuKhoiTaoService.FormData.SuDung" placeholder="0"
                            disabled type="number" style="text-align: right; color: red;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Còn lại</label>
                        <input name="ConLai" [(ngModel)]="TemPhieuKhoiTaoService.FormData.ConLai" placeholder="0"
                            disabled type="number" style="text-align: right; color: green;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tải lên danh sách (nếu có) [<a href="{{URLExcel}}" target="_blank"
                                title="Excel mẫu" class="link-primary">Tải về Excel mẫu</a>]</label>
                        <br />
                        <input type="file" (change)="ChangeFileName($event.target.files)">
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuKhoiTaoChiTietSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="TemPhieuKhoiTaoChiTietSearch()"><i class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tải về" (click)="TemPhieuKhoiTaoChiTietDownload()"><i
                            class="bi bi-cloud-download"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="TemPhieuKhoiTaoChiTietService.List">({{TemPhieuKhoiTaoChiTietService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietSort="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuKhoiTaoChiTietPaginator">
                                            {{TemPhieuKhoiTaoChiTietPaginator.pageSize *
                                            TemPhieuKhoiTaoChiTietPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="MaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số truy vết sản phẩm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.MaSo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="URL">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        URL dự kiến (Chưa tra cứu được)
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.URL}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="TemPhieuKhoiTaoChiTietDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container> -->
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumns001">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumns001">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietort="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã số: </label>
                                                <label class="form-label">{{element.MaSo}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>URL: </label>
                                                <label class="form-label">{{element.URL}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Chưa sử dụng</label>
                                                </div>
                                            </div>
                                            <!-- <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <a class="btn btn-danger" style="width: 100%;"
                                                        (click)="TemPhieuKhoiTaoChiTietDelete(element)"
                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                    </a>
                                                </div>
                                                <br />
                                                <br />
                                            </div> -->
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="TemPhieuKhoiTaoService.IsShowLoading"></app-loading>